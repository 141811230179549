/* TextHighlighter.css */
.c1 { background-color: #e5243b; } /* สีแดงสำหรับ c 1 */
.c2 { background-color: #dda63a; } /* สีส้มสำหรับ c 2 */
.c3 { background-color: #4c9f38; } /* สีเขียวสำหรับ c 3 */
.c4 { background-color: #c5192d; } /* สีแดงสำหรับ c 4 */
.c5 { background-color: #ff3a21; } /* สีส้มแดงสำหรับ c 5 */
.c6 { background-color: #26bde2; } /* สีฟ้าสำหรับ c 6 */
.c7 { background-color: #fcc30b; } /* สีเหลืองสำหรับ c 7 */
.c8 { background-color: #a21942; } /* สีแดงเข้มสำหรับ c 8 */
.c9 { background-color: #ff6927; } /* สีส้มสำหรับ c 9 */
.c10 { background-color: #dd1367; } /* สีชมพูสำหรับ c 10 */
.c11 { background-color: #fd9d24; } /* สีส้มเข้มสำหรับ c 11 */
.c12 { background-color: #bf8b2e; } /* สีน้ำตาลสำหรับ c 12 */
.c13 { background-color: #3f7e44; } /* สีเขียวเข้มสำหรับ c 13 */
.c14 { background-color: #0a97d9; } /* สีฟ้าเข้มสำหรับ c 14 */
.c15 { background-color: #56c02b; } /* สีเขียวสดสำหรับ c 15 */
.c16 { background-color: #00689d; } /* สีฟ้าเข้มสำหรับ c 16 */
.c17 { background-color: #19486a; } /* สีน้ำเงินเข้มสำหรับ c 17 */   /* Example color for c 17 */
.c18 { background-color: #545b5f; } /* สีน้ำเงินเข้มสำหรับ c 17 */   /* Example color for c 16 */

.c-gray { background-color: #cccccc; }